import { smooth } from './modules/smooth';
import { scrollmagic } from './modules/scrollmagic';
import { bnrAction } from './modules/bnr';
import { slider } from './modules/slider';
import { headerAction } from './modules/headerAction';
import { megaMenu } from './modules/megaMenu';
import { newsTabSelect } from './modules/newsTabSelect';

smooth();
scrollmagic();
bnrAction();
slider();
headerAction();
megaMenu();
newsTabSelect();

objectFitImages();